const environmentType = [
  {
    value: 0,
    label: "Development",
  },
  {
    value: 1,
    label: "Staging",
  },
  {
    value: 2,
    label: "Production",
  },
];

const convertEnvCodeToEnvString = (envCode, theme) => {
  let env = {};
  switch (envCode) {
    case 0:
      env.color = theme.palette.secondary.accent13;
      env.label = "DEVELOPMENT";
      break;
    case 1:
      env.color = theme.palette.secondary.accent14;
      env.label = "STAGING";
      break;
    case 2:
      env.color = theme.palette.secondary.accent1;
      env.label = "PRODUCTION";
      break;
    default:
      env.color = theme.palette.secondary.accent13;
      env.label = "DEVELOPMENT";
      break;
  }
  return env;
};

export { convertEnvCodeToEnvString, environmentType };
