//react components
import { Fragment, useEffect, useRef, useState } from "react";
//head components
import { Helmet } from "react-helmet-async";
//next.js
import { useRouter } from "next/router";
//auth.js
// import { auth } from '@auth'
//loadash
import { debounce } from "lodash";
//mui components
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
//sqlitecloud components
import CreateProject from "@components/Projects/CreateProject";
import FirstProject from "@components/Projects/FirstProject";
import ProjectsGrid from "@components/Projects/ProjectsGrid";
import OpenDialog from "@generic-components/OpenDialog";
import PageLoader from "@generic-components/PageLoader";
import PageTitle from "@generic-components/PageTitle";
import DashboardLayout from "@layouts/Dashboard";
//sqlitecloud hooks
import { useGetPlanInfo, useGetProjects } from "@custom-hooks/useApi";
import { useCheckAuth } from "@custom-hooks/useCheckAuth";
//sqlitecloud components
import UpgradingBanner from "@components/Banners/UpgradingBanner";
import DecorationLarge from "@generic-components/DecorationLarge";
//env variables
const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

function Default({}) {
  //check auth
  useCheckAuth(true, false);
  //get router
  const router = useRouter();
  const { query } = useRouter();
  //handle ref to create project dialog
  const createProjectRef = useRef(null);
  //read projects nodes
  const {
    projects,
    hasData: showTable,
    emptyData: showFirstProject,
    showLoader,
  } = useGetProjects();
  //get user plan and analyze user limitation
  const { planInfo } = useGetPlanInfo();
  const totalProjects = planInfo ? planInfo.projects : 0;
  const createdProjects = showTable ? projects.length : 0;
  const showCreateProject = !showLoader
    ? totalProjects - createdProjects > 0
    : false;
  //filter projects
  function searchItems(items, query) {
    if (query) {
      const lowerCaseQuery = query.toLowerCase();
      const filteredItems = items.filter((item) => {
        const lowerCaseName = item.name.toLowerCase();
        const lowerCaseDescription = item.description.toLowerCase();
        const lowerCaseId = item.id.toLowerCase();
        return (
          lowerCaseName.indexOf(lowerCaseQuery) !== -1 ||
          lowerCaseDescription.indexOf(lowerCaseQuery) !== -1 ||
          lowerCaseId.indexOf(lowerCaseQuery) !== -1
        );
      });
      return filteredItems;
    } else {
      return items;
    }
  }
  const [triggerFiltering, setTriggerFiltering] = useState(false);
  const handleTriggerFilter = debounce(() => {
    setTriggerFiltering(!triggerFiltering);
  }, 150);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchProjectsQuery, setSearchProjectsQuery] = useState(
    query.q ? query.q : ""
  );
  const handleUpdateSearchProjectsQuery = (event) => {
    const newQuery = event.target.value;
    setSearchProjectsQuery(newQuery);
    handleTriggerFilter();
  };
  useEffect(() => {
    const currentPath = router.asPath.split("?")[0];
    const newUrl = searchProjectsQuery
      ? `${currentPath}?q=${searchProjectsQuery}`
      : currentPath;
    if (showTable) {
      if (searchProjectsQuery) {
        const filteredProjects = searchItems(projects, searchProjectsQuery);
        setFilteredProjects(filteredProjects);
      } else {
        setFilteredProjects(projects);
      }
    } else {
      setFilteredProjects([]);
    }
    router.push(newUrl, undefined, { shallow: true });
  }, [showTable, projects, triggerFiltering]);

  return (
    <Fragment>
      <Helmet title="SQLite Cloud Dashboard" />
      <PageLoader show={false} />
      {!showFirstProject && (
        <Grid
          container
          maxWidth={"90rem"}
          width={"100%"}
          mx={"auto"}
          flexGrow={1}
          flexDirection={"column"}
          backgroundColor={"white"}
          flexWrap={"nowrap"}
          boxSizing={"border-box"}
        >
          {showTable && (
            <>
              <PageTitle
                mt={showLoader ? 2 : 3}
                title={"Projects"}
                banner={
                  UPGRADING_INFRA &&
                  UPGRADING_INFRA.toLocaleLowerCase() === "true" ? (
                    <UpgradingBanner />
                  ) : null
                }
              >
                <Grid item flexGrow={1} maxWidth={"58rem"}>
                  <form
                    style={{ width: "100%" }}
                    noValidate
                    autoComplete="off"
                    onSubmit={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    <TextField
                      value={searchProjectsQuery}
                      onChange={handleUpdateSearchProjectsQuery}
                      className="subvariant-modal-form"
                      type="search"
                      fullWidth
                      placeholder="Search Projects"
                      InputProps={{
                        startAdornment: <SearchIcon />,
                      }}
                    />
                  </form>
                </Grid>
                <Grid item>
                  {showCreateProject && (
                    <OpenDialog
                      ref={createProjectRef}
                      label={"Create Project"}
                      size="small-modal"
                    >
                      <CreateProject />
                    </OpenDialog>
                  )}
                </Grid>
              </PageTitle>
              <ProjectsGrid
                projects={filteredProjects}
                searchProjectsQuery={searchProjectsQuery}
                createProjectRef={createProjectRef}
              />
              <DecorationLarge />
            </>
          )}
        </Grid>
      )}
      {showFirstProject && <FirstProject />}
    </Fragment>
  );
}

Default.getLayout = function getLayout(page) {
  const { session } = page.props;
  return <DashboardLayout session={session}>{page}</DashboardLayout>;
};

export default Default;

// export const getServerSideProps = async context => {
//   const session = await auth(context)
//   if (!session) {
//     // Redirect to the sign-in page if the session does not exist
//     return {
//       redirect: {
//         destination: '/auth/sign-in',
//         permanent: false
//       }
//     }
//   }

//   return { props: { session } }
// }
