//react components

const DecorationLarge = () => {
  return (
    // <div style={{ position: "absolute", bottom: 0, width: "100%", overflow: "hidden", zIndex: -1, pointerEvents: "none", background: "white" }}>
    <div
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        zIndex: 0,
        pointerEvents: "none",
        background: "white",
      }}
    >
      <svg
        width="100%"
        viewBox="0 0 1440 125"
        style={{ marginBottom: "-1rem" }}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1_15833"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="-117"
          y="0"
          width="1557"
          height="532"
        >
          <path
            d="M1154 7.80364C1084.7 7.80364 1061.21 60.1507 1018.5 60.1507C975.789 60.1507 874.101 182.796 741.5 182.796C608.899 182.796 520.764 89.0869 464.5 89.0869C408.236 89.0869 358.778 0 290.5 0C156.8 0 185.564 113.46 -117 113.46L0 532H1440V76.7486C1358.75 77.474 1338.56 75.2397 1282 60.1507C1225.44 45.0618 1269.8 7.80364 1200.5 7.80364H1154Z"
            fill="#4A5154"
          />
        </mask>
        <g mask="url(#mask0_1_15833)">
          <line
            x1="2.5"
            y1="-44.5439"
            x2="2.49997"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="26.5"
            y1="-44.5439"
            x2="26.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="50.5"
            y1="-44.5439"
            x2="50.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="74.5"
            y1="-44.5439"
            x2="74.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="98.5"
            y1="-44.5439"
            x2="98.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="122.5"
            y1="-44.5439"
            x2="122.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="146.5"
            y1="-44.5439"
            x2="146.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="170.5"
            y1="-44.5439"
            x2="170.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="194.5"
            y1="-44.5439"
            x2="194.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="218.5"
            y1="-44.5439"
            x2="218.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="242.5"
            y1="-44.5439"
            x2="242.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="266.5"
            y1="-44.5439"
            x2="266.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="290.5"
            y1="-44.5439"
            x2="290.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="314.5"
            y1="-44.5439"
            x2="314.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="338.5"
            y1="-44.5439"
            x2="338.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="362.5"
            y1="-44.5439"
            x2="362.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="386.5"
            y1="-44.5439"
            x2="386.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="410.5"
            y1="-44.5439"
            x2="410.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="434.5"
            y1="-44.5439"
            x2="434.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="458.5"
            y1="-44.5439"
            x2="458.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="482.5"
            y1="-44.5439"
            x2="482.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="506.5"
            y1="-44.5439"
            x2="506.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="530.5"
            y1="-44.5439"
            x2="530.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="554.5"
            y1="-44.5439"
            x2="554.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="578.5"
            y1="-44.5439"
            x2="578.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="602.5"
            y1="-44.5439"
            x2="602.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="626.5"
            y1="-44.5439"
            x2="626.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="650.5"
            y1="-44.5439"
            x2="650.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="674.5"
            y1="-44.5439"
            x2="674.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="698.5"
            y1="-44.5439"
            x2="698.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="722.5"
            y1="-44.5439"
            x2="722.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="746.5"
            y1="-44.5439"
            x2="746.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="770.5"
            y1="-44.5439"
            x2="770.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="794.5"
            y1="-44.5439"
            x2="794.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="818.5"
            y1="-44.5439"
            x2="818.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="842.5"
            y1="-44.5439"
            x2="842.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="866.5"
            y1="-44.5439"
            x2="866.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="890.5"
            y1="-44.5439"
            x2="890.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="914.5"
            y1="-44.5439"
            x2="914.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="938.5"
            y1="-44.5439"
            x2="938.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="962.5"
            y1="-44.5439"
            x2="962.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="986.5"
            y1="-44.5439"
            x2="986.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1010.5"
            y1="-44.5439"
            x2="1010.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1034.5"
            y1="-44.5439"
            x2="1034.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1058.5"
            y1="-44.5439"
            x2="1058.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1082.5"
            y1="-44.5439"
            x2="1082.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1106.5"
            y1="-44.5439"
            x2="1106.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1130.5"
            y1="-44.5439"
            x2="1130.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1154.5"
            y1="-44.5439"
            x2="1154.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1178.5"
            y1="-44.5439"
            x2="1178.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1202.5"
            y1="-44.5439"
            x2="1202.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1226.5"
            y1="-44.5439"
            x2="1226.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1250.5"
            y1="-44.5439"
            x2="1250.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1274.5"
            y1="-44.5439"
            x2="1274.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1298.5"
            y1="-44.5439"
            x2="1298.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1322.5"
            y1="-44.5439"
            x2="1322.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1346.5"
            y1="-44.5439"
            x2="1346.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1370.5"
            y1="-44.5439"
            x2="1370.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1394.5"
            y1="-44.5439"
            x2="1394.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
          <line
            x1="1418.5"
            y1="-44.5439"
            x2="1418.5"
            y2="532.061"
            stroke="#578FDA"
            strokeWidth="3"
          />
        </g>
      </svg>
    </div>
  );
};

export default DecorationLarge;
