//react
//sqlitecloud hooks
import { useEditData } from "@custom-hooks/useApi";
//sqlitecloud components
import ModalContent from "@generic-components/ModalContent";
//env variables
const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

function DeleteProject({ project, setDisableRowDuringAction, callback }) {
  //get method to call editing api
  const editDataOpt = {
    errorCallback: () => {
      setDisableRowDuringAction(false);
      callback();
    },
    mutatedCallback: () => {
      setDisableRowDuringAction(false);
      callback();
    },
  };
  const { loading, mutatingData, editedData, error, editData } =
    useEditData(editDataOpt);
  //method call to remove node
  const remove = async (e) => {
    e.preventDefault();
    if (UPGRADING_INFRA && UPGRADING_INFRA.toLocaleLowerCase() === "true") {
      alert(
        "We're upgrading our infrastructure to serve you better! Deleting a project is temporarily disabled."
      );
    } else {
      setDisableRowDuringAction(true);
      const opt = {
        method: "GET",
        endpoint: `/api/projects/${project.id}/delete`,
        endpointCallLocation: "DeleteProject.js",
        mutateApis: [["/api/projects", "useGetProjects"]],
      };
      await editData(opt);
    }
  };
  //render
  return (
    <ModalContent
      actionType="strong-confirm"
      title={`Delete project?`}
      showLoader={loading || mutatingData}
      disabled={loading || mutatingData || error}
      noCallback={callback}
      yesCallback={remove}
      resourceType="project"
      confirmString={project.name}
      verifyString="delete my project"
    />
  );
}

export default DeleteProject;
