//react
//mui components
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
//sqlitecloud components
import Card from "@generic-components/Card";
import ArrowSvg from "@generic-components/SVG/ArrowSvg";
import SingleProjectCard from "./SingleProjectCard";

const ProjectsGrid = ({ projects, searchProjectsQuery, createProjectRef }) => {
  //get theme
  const theme = useTheme();
  //extract num of projects
  const numOfProjects = projects.length;
  // render
  return (
    <Grid item flexGrow={1}>
      {numOfProjects > 0 && (
        <Grid
          container
          spacing={{ xs: 4, md: 3, lg: 5 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
          py={12}
        >
          {projects.map((project, i) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={i}>
              <SingleProjectCard project={project} />
            </Grid>
          ))}
        </Grid>
      )}
      {numOfProjects === 0 && (
        <Grid item>
          <Card
            hoverType={"info-1"}
            customSx={{ mt: 12, padding: "1.25rem 1.5rem", maxWidth: "36rem" }}
          >
            <Typography as="div" variant="14px-med" gutterBottom={true} mb={2}>
              No Results Found
            </Typography>
            <Typography
              as="div"
              variant="14px-med"
              color={theme.palette.neutral.darkGreyBis}
              gutterBottom={true}
              mb={4}
            >
              Your search for "{searchProjectsQuery}" did not return any
              results.
            </Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              columnGap={4}
            >
              <Grid item>
                <Typography as="div" variant="14px-light" align="left">
                  Create Project
                </Typography>
              </Grid>
              <Grid item>
                <ArrowSvg
                  onClick={() => {
                    createProjectRef.current.open();
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectsGrid;
