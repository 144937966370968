//react component
import React, { useState } from "react";
//css library
import { useTheme } from "@mui/material/styles";
//mui components
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

/**
 * 	menuItems = [
		{
			type: "button",
			label: "test",
			action: () => { console.log("test action") },
			icon: <ChatIcon fontSize="small" />
		},
		{
			type: "divider",
		}
		{
			label: "test2",
			disabled: true,
			action: () => { console.log("test action2") },
			icon: <ContentCopy fontSize="small" />
		},
		{
			type: "component",
			component: <DownloadDatabase database={database} setDeleteConnectionError={setDeleteConnectionError} enabledBackup={database.backup !== 0} />
		},
	]
	* 
	* iconStyle = "arrow" | "points"
 */
//sqlitecloud compoments
function RowTableActionsMenu({
  menuItems,
  menuWidth = 240,
  actionOnOpen = null,
  actionOnClose = null,
  iconStyle = "arrow",
}) {
  const theme = useTheme();
  let sx = {
    height: "24px",
    width: "32px",
    borderRadius: "18px",
    cursor: "pointer",
    padding: 0,
    margin: 0,
  };

  if (iconStyle === "arrow") {
    sx.background = theme.palette.neutral.pale;
    sx.color = theme.palette.secondary.accent2;
    sx["&:hover"] = {
      backgroundColor: theme.palette.secondary.accent2,
      color: "#FFFFFF",
    };
  }

  if (iconStyle === "points") {
    sx.color = theme.palette.neutral.darkGreyBis;
    sx["&:hover"] = {
      backgroundColor: theme.palette.neutral.lightestGrey,
    };
  }

  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (actionOnOpen) {
      if (Array.isArray(actionOnOpen) && actionOnOpen.length > 0) {
        actionOnOpen.forEach((action) => action());
      } else {
        actionOnOpen();
      }
    }
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = (event) => {
    if (event) {
      event.stopPropagation();
    }
    if (actionOnClose) {
      if (Array.isArray(actionOnClose) && actionOnClose.length > 0) {
        actionOnClose.forEach((action) => action());
      } else {
        actionOnClose();
      }
    }
    setAnchorMenu(null);
  };

  const handleMenuItemClick = (action) => {
    action();
    closeMenu();
  };

  return (
    <>
      {iconStyle === "points" && (
        <IconButton
          sx={sx}
          aria-owns={Boolean(anchorMenu) ? "actions-menu" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="primary"
        >
          <MoreHorizIcon sx={{ width: "24px" }} />
        </IconButton>
      )}
      {iconStyle === "arrow" && (
        <IconButton
          sx={sx}
          aria-owns={Boolean(anchorMenu) ? "actions-menu" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="primary"
          disableFocusRipple={true}
          disableRipple={true}
        >
          <KeyboardArrowDownIcon sx={{ width: "24px" }} />
        </IconButton>
      )}
      <Menu
        id="actions-menu"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList
          onClick={(event) => {
            event.preventDefault();
          }}
          sx={{ width: menuWidth, maxWidth: "100%", padding: 0 }}
        >
          {menuItems.map((item, i) => {
            if (item) {
              return (
                <div key={i}>
                  {item.type === "button" && (
                    <MenuItem
                      disabled={item.disabled}
                      onClick={(event) => {
                        handleMenuItemClick(item.action);
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText>{item.label}</ListItemText>
                    </MenuItem>
                  )}
                  {item.type === "component" && (
                    <>
                      {React.Children.map(item.component, (child) =>
                        React.cloneElement(child, { callback: closeMenu })
                      )}
                    </>
                  )}
                  {item.type === "divider" && <Divider key={i} />}
                </div>
              );
            }
          })}
        </MenuList>
      </Menu>
    </>
  );
}

export default RowTableActionsMenu;
